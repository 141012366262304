.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-upload-drag{
  position: relative !important;
  height: 160px !important;
}

.ant-upload-list-item-container{
   width: 300px !important;
}
.btn-info{
  background-color: #222FB9 !important;
  border: 1px solid #222FB9 !important;
}

.nav-pills li button{
  border: 1px solid #222FB9 !important;
  background-color: #fff !important;
  color: #282c34 !important;
}


.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color) !important;
  background-color: #222FB9 !important;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 7px;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.widget{
  
  background-color: #222FB9 !important;
  min-height: 120px !important;

}

.btn-danger{
  background-color: #222fb9 !important;
  border: 1px solid #222fb9 !important;
}


.dataTables_wrapper .dataTables_paginate .paginate_button.current{
   /* background-color: #222FB9 !important; */
   color: #fff !important;
}


.forms_cards input{
  border-bottom: 1px solid #000 !important;
  border: none ;
  background-color: transparent !important;
  border-radius: 0px !important;
}


 .forms_cards label{
  font-size: 18px !important;
 }

 .buttons-html5{
  background-color: #222FB9 !important;
  border: none !important;
  color: #fff !important;
  padding: 6px 12px  !important;
  border-radius: 6px !important;
 }

 .buttons-print{
  background-color: #222FB9 !important;
  border: none !important;
  color: #fff !important;
  padding: 6px 12px  !important;
  border-radius: 6px !important;
 }

 .dt-buttons{
  position: absolute !important;
 }





@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.sidebar-wrapper {
  background-clip: padding-box;
  background-color: rgb(34,47,185);
  border-right: 1px solid #e2e3e4;
  height: 100%;
  left: 0;
  overflow-y:scroll;
  position: fixed;
  top: 0;
  transition: all .2s;
  width: 300px;
  z-index: 16;
}

.sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

.sidebar-wrapper .metismenu {
  background: 0 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
}


.sidebar-wrapper ul {
  background: 0 0;
  list-style: none;
  margin: 0;
  padding: 0;
}


.sidebar-wrapper .metismenu li+li, .sidebar-wrapper .metismenu li:first-child {
  margin-top: 5px;
}
.sidebar-wrapper .metismenu>li {
  display: flex;
  flex-direction: column;
  position: relative;
}


.sidebar-wrapper .metismenu a {
  align-items: center;
  border-left: 4px solid hsla(0,0%,100%,0);
  border-radius: 4px;
  color: #d2d2d2;
  display: flex;
  font-size: 15px;
  justify-content: left;
  letter-spacing: .5px;
  outline-width: 0;
  overflow: hidden;
  padding: 10px 15px;
  position: relative;
  text-overflow: ellipsis;
  transition: all .2s ease-out;
}

.metismenu .accordion-collapse {
  margin: 0;
}


a {
  text-decoration: none;
  
}


.accordion-button:hover{
  background: rgba(255,255,255,0.4);
  border-radius: 30px !important;
  padding-left: 0px !important;
  /* padding-right: 0px !important; */
}

.accordion-button{
  padding-left: 0px !important;
}

.accordion-button:hover i{
  border-radius: 50%;
  background-color: white;
  padding: 15px;
  color: blue;
  /* width: 40px;
  height: 40px; */
}



.h-100 {
  height: 100vh !important;
}
@media (min-width: 1200px)
.container, .container-sm, .container-md, .container-lg, .container-xl {
  max-width: 1140px;
}
@media (min-width: 992px)
.container, .container-sm, .container-md, .container-lg {
  max-width: 960px;
}
@media (min-width: 768px)
.container, .container-sm, .container-md {
  max-width: 720px;
}
@media (min-width: 576px)
.container, .container-sm {
  max-width: 540px;
}
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}
* {
  outline: none;
  padding: 0;
}
*, *::before, *::after {
  box-sizing: border-box;
}


.authincation-content {
  background: #fff;
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  border-radius: 5px;
}
* {
  outline: none;
  padding: 0;
}
*, *::before, *::after {
  box-sizing: border-box;
}

.auth-form {
  padding: 50px 50px;
}
* {
  outline: none;
  padding: 0;
}


.btn-primary{
  background-color: rgb(34,47,185);
  border: rgb(34,47,185);
}